<template>

<app-input :label="label" :disabled="disabled">

	<div class="select-value" :class="{'has-value': value}" v-on:click.stop="expanded = !expanded">{{ text }} <i class="fa fa-caret-down" /></div>

	<div class="select-options" v-if="expanded">
		<div class="select-options-item" v-for="option in options" :key="option.id" v-on:click="$emit('input', option.id)">{{ option.name }}</div>
	</div>

</app-input>

</template>

<script>

export default {

	props: ['label', 'value', 'options', 'placeholder', 'disabled'],

	data: function() {

		return {
			expanded: false
		}

	},

	computed: {

		text: function() {

			return (this.value) ? this.$_.findWhere(this.options, {
				id: this.value
			}).name : this.placeholder

		}

	},

	created: function() {

		window.addEventListener('click', this.onClose.bind(this))

	},

	beforeDestroy: function() {

		window.removeEventListener('click', this.onClose.bind(this))

	},

	methods: {

		onClose: function() {

			this.expanded = false

		}
	
	}

}

</script>

<style scoped>

.select-value {
	padding: 8px 4px;
	font-size: 14px;
	width: 100%;
	border: 2px solid #dadada;
	color: #666;
	border-radius: 4px;
	cursor: pointer;
}

.select-value.has-value {
	color: #333;
}

.select-value .fa {
	position: absolute;
	right: 16px;
	top: 8px;
}

.select-options {
	position: absolute;
	left: 0px;
	width: 100%;
	top: 32px;
	background-color: #fff;
	border: 2px solid #dadada;
	border-top-width: 1px;
	border-radius: 0px 0px 4px 4px;
	box-shadow: rgba(100, 100, 111, 0.3) 0px 7px 29px 0px;
	z-index: 50;
}

.select-options-item {
	padding: 8px 4px;
	font-size: 14px;
	color: #333;
	cursor: pointer;
}

</style>
