<template>

<app-modal :is-form="true" :is-invalid="$v.$invalid" v-on:save="onSaveClick" v-on:cancel="onClose" :loading="is.saving">

	<app-input-text label="Title" :maxlength="128" v-model="model.title" />
	<app-input-text label="Description" :autogrow="true" v-model="model.description" />
	<app-input-text label="Speech" :autogrow="true" v-model="model.speech" />
	<app-input-text label="URL" v-model="model.link" />
	<app-input-text label="Image" v-model="model.thumbnail" />
	<app-input-text label="Audio" v-model="model.audio" />
	<app-input-tags label="Tags" v-model="model.tags" placeholder="Enter tag..." />
	<app-input-date label="Expiry" v-model="model.expiry" placeholder="Enter expiry..." />
	<app-input-select v-if="$manageOrganisations" label="Organisation" v-model="model.organisation_id" placeholder="Select organisation..." :options="$organisations" />
	<app-input-select :disabled="!model.organisation_id" label="Group" v-model="model.group_id" placeholder="Select group..." :options="$groupsByOrganisation[model.organisation_id]" />
	<app-input-select :disabled="!model.organisation_id" label="Centre" v-model="model.centre_id" placeholder="Select centre..." :options="$centresByOrganisation[model.organisation_id]" />

</app-modal>

</template>

<script>

import mixForm from '@/mixin/form'
import { required } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],
	
	data: function() {

		return {
			model: {
				id: false,
				title: '',
				description: '',
				speech: '',
				link: '',
				audio: '',
				thumbnail: '',
				tags: [],
				expiry: null,
				organisation_id: 0,
				group_id: 0,
				centre_id: 0
			},
			api: 'resources'
		}

	},

	validations: {
		model: {
			title: {
				required
			},
			description: {
				required
			},
			speech: {
				required
			},
			link: {
				required
			}
		}
	},

	methods: {

		setDefaults: function() {

			if (this.$store.getters['organisation']) {

				this.model.organisation_id = this.$store.getters['organisation']

			}

		},

		onClose: function() {

			this.$router.push({
				name: 'Resources'
			})

		}

	}

}

</script>

<style scoped>

</style>
