<template>

<app-modal :is-confirm="true" v-on:confirm="onConfirm" v-on:cancel="onClose">

	<div class="delete-text">Are you sure you wish to delete this?</div>

</app-modal>

</template>

<script>

import axios from 'axios'

export default {

	data: function() {

		return {
			loading: false,
			errored: true
		}

	},

	methods: {

		onConfirm: function() {

			this.loading = true

			axios.delete(this.$route.meta.api + '/' + this.$route.params.id).then(function() {

				this.onClose()

			}.bind(this), function() {

				this.loading = false
				this.errored = true

			}.bind(this))

		},

		onClose: function() {

			this.$router.push({
				name: this.$route.meta.parent
			})

		}

	}

}

</script>

<style scoped>

.delete-text {
	width: 100%;
	font-size: 16px;
	text-align: center;
	margin-bottom: 32px;
}

</style>
