import Vue from 'vue'
import Router from 'vue-router'

import Resources from '@/components/page/Resources'
import Videos from '@/components/page/Videos'
import Leaflets from '@/components/page/Leaflets'
import Questions from '@/components/page/Questions'
import Login from '@/components/page/Login'

import ModalResource from '@/components/modal/Resource'
import ModalVideo from '@/components/modal/Video'
import ModalLeaflet from '@/components/modal/Leaflet'
import ModalQuestion from '@/components/modal/Question'

import ModalDelete from '@/components/modal/Delete'

Vue.use(Router)

export default new Router({
	mode: 'history',
	routes: [
		{ name: 'Dashboard', path: '/', meta: {}, component: Videos },
		{ name: 'Login', path: '/login', meta: {}, component: Login },
		{ name: 'Resources', path: '/resources', meta: {}, component: Resources, children: [
			{ name: 'ResourcesCreate', path: '/resources/create', meta: { modal: true }, component: ModalResource },
			{ name: 'ResourcesEdit', path: '/resources/:id', meta: { modal: true }, component: ModalResource },
			{ name: 'ResourcesDelete', path: '/resources/:id/delete', meta: { parent: 'Resources', modal: true, api: 'resources' }, component: ModalDelete },
		] },
		{ name: 'Videos', path: '/videos', meta: {}, component: Videos, children: [
			{ name: 'VideosCreate', path: '/videos/create', meta: { modal: true }, component: ModalVideo },
			{ name: 'VideosEdit', path: '/videos/:id', meta: { modal: true }, component: ModalVideo },
			{ name: 'VideosDelete', path: '/videos/:id/delete', meta: { parent: 'Videos', modal: true, api: 'videos' }, component: ModalDelete },
		] },
		{ name: 'Leaflets', path: '/leaflets', meta: {}, component: Leaflets, children: [
			{ name: 'LeafletsCreate', path: '/leaflets/create', meta: { modal: true }, component: ModalLeaflet },
			{ name: 'LeafletsEdit', path: '/leaflets/:id', meta: { modal: true }, component: ModalLeaflet },
			{ name: 'LeafletsDelete', path: '/leaflets/:id/delete', meta: { parent: 'Leaflets', modal: true, api: 'leaflets' }, component: ModalDelete },
		] },
		{ name: 'Questions', path: '/questions', meta: {}, component: Questions, children: [
			{ name: 'QuestionsCreate', path: '/questions/create', meta: { modal: true }, component: ModalQuestion },
			{ name: 'QuestionsEdit', path: '/questions/:id', meta: { modal: true }, component: ModalQuestion },
			{ name: 'QuestionsDelete', path: '/questions/:id/delete', meta: { parent: 'Questions', modal: true, api: 'questions' }, component: ModalDelete },
		] }
	]
})