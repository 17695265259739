export default {

	roleName: {
		global: 'Global Admin',
		organisation: 'Organisation Admin',
		contributor: 'Contributor',
		user: 'User'
	},

	role: {
		global: 'global',
		organisation: 'organisation',
		contributor: 'contributor',
		user: 'user'
	}

}