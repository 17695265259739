<template>

<div id="app">

	<com-head v-if="authorised" />

	<i class="fa fa-spinner fa-spin app-loading" v-if="!authorised" />

	<com-content :fullscreen="!authorised" v-if="loaded">

		<router-view></router-view>

	</com-content>

</div>

</template>

<script>

import axios from 'axios'

import comHead from './components/Head'
import comContent from './components/Content'

export default {

	name: 'App',

	components: {
		'com-head': comHead,
		'com-content': comContent
	},

	data: function() {

		return {
			loaded: false,
			loading: true
		}

	},

	computed: {

		authorised: function() {

			return this.$store.getters['authorised']

		}

	},

	created: async function() {

		await this.$store.dispatch('init')

		if (this.$route.query.code) {

			this.validate()

		} else if (this.$store.getters['token']) {

			this.authorise()

		} else {

			this.loaded = true

			this.$router.push({
				name: 'Login'
			})

		}

	},

	methods: {

		validate: async function() {

			await axios.post('login', {
				state: this.$route.query.state,
				code: this.$route.query.code,
				role: ['global', 'organisation', 'contributor'],
				redirect: process.env.VUE_APP_URL
			}).then(function(response) {

				this.$store.commit('token', response.data.token)
				
				this.authorise()

			}.bind(this), function() {

				this.loaded = true

				this.$store.commit('token', false)

				this.$router.push({
					name: 'Login'
				})

			}.bind(this))

		},

		authorise: async function() {

			await axios.get('session').then(async function(response) {

				this.$store.commit('name', response.data.name)
				this.$store.commit('organisation', response.data.organisation)
				this.$store.commit('role', response.data.role)

				await axios.get('organisations').then(function(response) {

					this.$store.commit('data/organisations', response.data)

				}.bind(this))

				await axios.get('groups').then(function(response) {

					this.$store.commit('data/groups', response.data)

				}.bind(this))

				await axios.get('centres').then(function(response) {

					this.$store.commit('data/centres', response.data)

				}.bind(this))

				this.$store.commit('authorised', true)

				this.loaded = true

				this.$router.push({
					name: 'Dashboard'
				})
				
			}.bind(this), function() {

				this.$store.commit('token', false)

				this.loaded = true

				this.$router.push({
					name: 'Login'
				})

			}.bind(this))

		}

	}

}

</script>

<style scoped>

#app {
	background-color: #5B108B;
	position: fixed;
	overflow: hidden;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
}

.app-loading {
	font-size: 32px;
	position: absolute;
	left: 50%;
	top: 50%;
	color: #fff;
	transform: translate(-50%, -50%);
}

</style>
