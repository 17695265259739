<template>

<div class="login" :fullscreen="true" :centered="true">

	<div class="login-logo" />

	<div class="login-reason" v-if="$route.params.reason">{{ reasons[$route.params.reason] }}</div>

	<div class="login-text">Please login with your Alzheimer Scotland digital account.</div>

	<app-button text="Login" class="login-button" :loading="is.loading" theme="white" v-on:click="onLoginClick" />

</div>
	
</template>

<script>

import axios from 'axios'

export default {

	data: function() {

		return {
			is: {
				loading: false
			},
			reasons: {
				unauthorized: 'Sorry, but you are not authorized to use this tool.',
				failed: 'Sorry, your authorisation could not be validated. Please try again.'
			}
		}

	},

	methods: {

		onLoginClick: function() {

			this.is.loading = true

			axios.get('login', {
				params: {
					redirect: process.env.VUE_APP_URL
				}
			}).then(function(response) {

				window.location.href = response.data.redirect

			})

		}

	}

}

</script>

<style scoped>

.login {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	align-items: center;
}

.login-logo {
	width: 100%;
	height: 100px;
	margin-bottom: 30px;
	background-image: url(https://www.alzscot.org/themes/custom/numiko/src/img/svg/alzscot_logo_white.svg);
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

.login-reason {
	background-color: #ee5c5c;
	color: #fff;
	padding: 10px;
	border-radius: 10px;
	font-size: 16px;
	font-weight: 400;
	margin-bottom: 20px;
}

.login-text {
	font-size: 16px;
	line-height: 20px;
	color: #fff;
	font-size: 400;
	margin-bottom: 20px;
}

.login-button {
	width: 200px;
}

</style>