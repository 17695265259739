import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import cookies from 'js-cookie'

Vue.use(Vuex)

export default new Vuex.Store({

	state: {
		token: '',
		authorised: false,
		name: '',
		organisation: 0,
		role: '',
		data: {
			organisations: [],
			centres: [],
			groups: []
		}
	},

	getters: {

		authorised: function(state) {

			return state.authorised

		},

		token: function(state) {

			return state.token

		},

		name: function(state) {

			return state.name

		},

		organisation: function(state) {

			return state.organisation

		},

		role: function(state) {

			return state.role

		},

		data: function(state) {

			return state.data

		}

	},

	mutations: {

		authorised: function(state, value) {

			state.authorised = value

		},

		token: function(state, value) {

			state.token = (value) ? value : ''

			cookies.set('token', value)

			axios.defaults.headers.common['Authorization'] = (value) ? 'Bearer ' + value : false

		},

		name: function(state, value) {

			state.name = value

		},

		organisation: function(state, value) {

			state.organisation = value

		},

		role: function(state, value) {

			state.role = value

		},

		'data/organisations': function(state, value) {

			state.data.organisations = value

		},

		'data/groups': function(state, value) {

			state.data.groups = value

		},

		'data/centres': function(state, value) {

			state.data.centres = value

		}

	},

	actions: {

		init: function(context) {

			if (cookies.get('token')) context.commit('token', cookies.get('token'))

		}

	}

})
