import '@/assets/reset.css'

import Vue from 'vue'
import App from './App.vue'

import _ from 'underscore'
import axios from 'axios'
import moment from 'moment'
import autogrow from 'vue-textarea-autogrow-directive'
import VCalendar from 'v-calendar'

import router from './router'
import store from './store'
import constants from './constants'

import mixInjection from './mixin/injection'
import mixShortcuts from './mixin/shortcuts'

axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.baseURL = process.env.VUE_APP_API

var requireComponentGlobal = require.context('./components/common', true, /[A-Z]\w+\.(vue|js)$/)

requireComponentGlobal.keys().forEach(function(filename) {

	var componentConfig = requireComponentGlobal(filename)
	var componentName = 'app' + filename.replace(/\//g, '-').replace('.vue', '').replace('.', '').toLowerCase()

	Vue.component(
		componentName,
		componentConfig.default
	)

})

Vue.config.productionTip = false

Vue.use(autogrow)
Vue.use(VCalendar)

Vue.mixin(mixInjection)
Vue.mixin(mixShortcuts)

new Vue({
	router,
	constants,
	moment,
	_,
	store,
	render: h => h(App)
}).$mount('#app')
