<template>

<app-table :loading="loading">

	<router-view />

	<app-table-head v-if="items.length">

		<app-table-head-cell>Title</app-table-head-cell>
		<app-table-head-cell width="200px">Tags</app-table-head-cell>
		<app-table-head-cell width="300px" class="featured-cell">Availability</app-table-head-cell>
		<app-table-head-cell width="100px" right="true">

			<router-link :to="{name: 'QuestionsCreate'}" class="avatar-add"><i class="fa fa-plus" /></router-link>

		</app-table-head-cell>

	</app-table-head>

	<app-table-row v-for="(item, index) in items" :key="index">

		<app-table-cell>{{ item.title }}</app-table-cell>
		<app-table-cell width="200px" class="tags">

			<app-tag v-for="(tag, index) in item.tags" :key="index" :tag="tag" />

			<template v-if="!item.tags.length">No tags.</template>

		</app-table-cell>
		<app-table-cell width="300px" class="featured-cell">

			<div class="featured-cell-item" v-if="item.centre_id"><b>Centre</b> {{ $centresAssociated[item.centre_id].name }}</div>
			<div class="featured-cell-item" v-if="item.group_id"><b>Group</b> {{ $groupsAssociated[item.group_id].name }}</div>
			<div class="featured-cell-item" v-if="item.organisation_id"><b>Organisation</b> {{ $organisationsAssociated[item.organisation_id].name }}</div>
			<div class="featured-cell-item" v-if="!item.organisation_id">Public</div>

		</app-table-cell>
		<app-table-cell width="100px">

			<app-table-row-actions>

				<app-table-row-actions-item icon="pencil" text="Edit" v-on:click="onEditClick(item.id)" />
				<app-table-row-actions-item icon="trash" text="Delete" v-on:click="onDeleteClick(item.id)" />

			</app-table-row-actions>

		</app-table-cell>

	</app-table-row>

	<app-table-empty v-if="!items.length" :to="{name: 'QuestionsCreate'}" button="Add question">No questions added.</app-table-empty>

</app-table>

</template>

<script>

import axios from 'axios'

export default {

	data: function() {

		return {
			loading: true,
			items: []
		}

	},

	created: function() {

		this.fetch()

	},

	watch: {

		$path: function() {

			if (!this.$route.meta.modal) this.fetch()

		}

	},

	methods: {

		onEditClick: function(id) {

			this.$router.push({
				name: 'QuestionsEdit',
				params: {
					id: id
				}
			})
			
		},

		onDeleteClick: function(id) {

			this.$router.push({
				name: 'QuestionsDelete',
				params: {
					id: id
				}
			})

		},

		fetch: function() {

			this.loading = true

			axios.get('questions').then(function(response) {		

				this.items = response.data
				this.loading = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.avatar-add {
	font-size: 18px;
	background-color: #5B108B;
	color: #fff;
	width: 32px;
	height: 32px;
	margin-top: 4px;
	display: inline-block;
	border-radius: 16px;
	text-align: center;
	line-height: 34px;
}

.featured-cell {
	display: flex;
	flex-direction: column;
}

.featured-cell-item {
	font-size: 16px;
	line-height: 24px;
}

.featured-cell-item b {
	font-size: 12px;
	line-height: 18px;
	display: block;
}

.tags {
	display: flex;
	align-items: flex-start;
}

</style>
