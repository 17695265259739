<template>

<div class="input" :class="{'is-disabled': disabled}">

	<div class="input-label">{{ label }}</div>
	<div class="input-content">

		<slot></slot>

	</div>

</div>

</template>

<script>

export default {

	props: ['label', 'disabled']

}

</script>

<style scoped>

.input {
	margin-bottom: 16px;
}

.input.is-disabled {
	opacity: 0.25;
	pointer-events: none;
}

.input-label {
	font-size: 16px;
	color: #666;
	margin-bottom: 4px;
}

</style>
