<template>

<div class="content" :class="{'is-fullscreen': fullscreen}">

	<slot></slot>

</div>

</template>

<script>

export default {

	props: ['fullscreen']

}

</script>


<style scoped>

.content {
	position: fixed;
	top: 100px;
	left: 0px;
	width: 100%;
	bottom: 0px;
	overflow: auto;
	padding: 32px;
	background-color: #fff;
}

.content.is-fullscreen {
	top: 0px;
	background-color: #5b108b;
}

</style>