export default {

	computed: {

		$organisations: function() {

			return this.$store.getters['data'].organisations

		},

		$groups: function() {

			return this.$store.getters['data'].groups

		},

		$centres: function() {

			return this.$store.getters['data'].centres

		},

		$organisationsAssociated: function() {

			return this.$_.indexBy(this.$store.getters['data'].organisations, 'id')

		},

		$groupsAssociated: function() {

			return this.$_.indexBy(this.$store.getters['data'].groups, 'id')

		},

		$centresAssociated: function() {

			return this.$_.indexBy(this.$store.getters['data'].centres, 'id')

		},

		$groupsByOrganisation: function() {

			return this.$_.groupBy(this.$store.getters['data'].groups, function(group) {

				return group.organisation_id

			})

		},

		$centresByOrganisation: function() {

			return this.$_.groupBy(this.$store.getters['data'].centres, function(centre) {

				return centre.organisation_id

			})

		},

		$path: function() {

			return this.$route.path

		},
	
		$role: function() {
	
			return this.$store.getters['role'] 
	
		},
	
		$manageOrganisations: function() {
	
			return this.$role === this.$constants.role.global
	
		}

	}

}