<template>

<button class="button" :class="{[clsTheme]: true, 'is-loading': loading}" v-on:click="$emit('click')">

	<div class="button-text">{{ text }}</div>

	<app-icon icon="loading" v-if="loading" class="button-loading" />

</button>
	
</template>

<script>

export default {

	props: ['text', 'theme', 'loading'],

	computed: {

		clsTheme: function() {

			return 'theme-' + this.theme

		}

	}

}

</script>

<style scoped>

.button {
	padding: 0px 20px;
	line-height: 40px;
	font-size: 16px;
	border-radius: 4px;
	font-weight: 700;
	transition: transform 100ms linear;
}

.button.theme-white {
	background-color: #fff;
	color: #5b108b;
}

.button:hover {
	transform: scale(1.05);
}

.button.is-loading .button-text {
	visibility: hidden;
}

.button-loading {
	font-size: 16px;
	color: #5b108b;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

</style>