<template>

<app-input :label="label">

	<input class="tags-input" v-model="tag" :placeholder="placeholder" v-on:keypress.enter="onAdd" />

	<div class="tags-list" v-if="value.length">

		<app-tag v-for="(tag, index) in value" :isRemovable="true" :key="index" :tag="tag" v-on:remove="onRemove(index)" />

	</div>

</app-input>

</template>

<script>

export default {

	props: ['label', 'value', 'placeholder'],

	data: function() {

		return {
			tag: ''
		}
		
	},

	methods: {

		onAdd: function() {

			if (!this.tag) return false

			var tags = this.$_.clone(this.value)
			tags.push(this.tag)
			this.$emit('input', tags)
			this.tag = ''

		},

		onRemove: function(index) {

			var tags = this.$_.clone(this.value)
			tags.splice(index, 1)
			this.$emit('input', tags)

		}

	}

}

</script>

<style scoped>

.tags-input {
	background-color: #fff;
	border: 2px solid #dadada;
	border-radius: 4px;
	width: 100%;
	padding: 8px 4px;
	font-size: 14px;
}

.tags-list {
	margin-top: 4px;
	display: flex;
}

</style>
