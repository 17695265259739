import _ from 'underscore'

export default {

	beforeCreate: function() {

		const options = this.$options

		_.each(['_', 'constants', 'moment', 'store'], function(key) {

			if (options[key]) {
				this['$' + key] = options[key]
			} else if (options.parent && options.parent['$' + key]) {
				this['$' + key] = options.parent['$' + key]
			}

		}.bind(this))

	}

}