<template>

<div class="modal">

	<div class="modal-content">

		<div class="modal-body">
		
			<slot></slot>

		</div>
		
		<div class="modal-foot" v-if="isForm">

			<app-button v-on:click.native="$emit('cancel')" theme="plain" text="Cancel" />
			<app-button :disabled="isInvalid" v-on:click.native="$emit('save')" theme="purple" text="Save" />

		</div>
		
		<div class="modal-foot" v-if="isConfirm">

			<app-button v-on:click.native="$emit('cancel')" theme="plain" text="Cancel" />
			<app-button :loading="loading" v-on:click.native="$emit('confirm')" theme="red" text="Confirm" />

		</div>

	</div>

</div>

</template>

<script>

export default {

	props: ['isForm', 'isConfirm', 'isInvalid', 'loading']

}

</script>

<style scoped>

.modal {
	background-color: rgba(0, 0, 0, 0.8);
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 100;
}

.modal-content {
	position: absolute;
	left: 50%;
	top: 50%;
	width: calc(100% - 20px);
    max-width: 640px;
    overflow: auto;
    max-height: calc(100% - 20px);
	background-color: #fff;
	transform: translate(-50%, -50%);
	border-radius: 16px;
	padding: 32px;
}

.modal-foot {
	display: flex;
	justify-content: center;
}

.modal-foot >>> .button {
	margin: 0px 16px;
	min-width: 120px;
}

</style>
