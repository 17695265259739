<template>

<div class="icon">

	<i :class="{[clsIcon]: true}" />

</div>
	
</template>

<script>

export default {

	props: ['icon'],

	data: function() {

		return {
			icons: {
				customise: 'fas fa-pen',
				'create-circle': 'fa-solid fa-circle-plus',
				search: 'fas fa-search',
				warning: 'fa-solid fa-exclamation-triangle',
				create: 'fa-solid fa-square-plus',
				empty: 'fa-solid fa-face-frown',
				undo: 'fa-solid fa-undo',
				back: 'fa fa-chevron-left',
				save: 'fa-solid fa-floppy-disk',
				delete: 'fa fa-trash',
				loading: 'fa-solid fa-spinner fa-spin'
			}
		}

	},

	computed: {

		clsIcon: function() {

			return this.icons[this.icon]

		}

	}

}

</script>

<style scoped>

</style>